import { Box, Flex, Text, Button, Link } from '@chakra-ui/react';
import FileNameSize from '../file-name-size';
import { useMutation } from 'react-query';
import { deleteTicket } from 'apis/tickets';
import png from 'assets/images/icons/png.svg';
import jpg from 'assets/images/icons/jpg.svg';
import pdf from 'assets/images/icons/pdf.svg';
import xlsx from 'assets/images/icons/xlsx.svg';
import docx from 'assets/images/icons/docx.svg';
import { useTranslation } from 'react-i18next';
import { StatusBox } from 'components/authenticated/shared';

const TicketType = ({
  type,
  description,
  id,
  attachments,
  setShowChat,
  refetch,
}) => {
  const { t } = useTranslation();

  const { mutate } = useMutation(deleteTicket, {
    onSuccess: () => {
      refetch();
      setShowChat(false);
    },
  });

  return (
    <Box bg="white" borderRadius="10px" p="20px">
      <Flex alignItems="center" justifyContent="space-between" mb="10px">
        <Text color="#232728" fontSize="14px" fontWeight="bold" me="30px">
          {t('ticket.ticketType')}:
        </Text>
        <StatusBox
          text={
            type == 'incident'
              ? t('status.incident')
              : type == 'service'
              ? t('status.service')
              : t('status.suggestion')
          }
          status={type}
        />
        {/* <Box
          bg="#7686CA"
          borderRadius="5px"
          py="5px"
          w="121px"
          fontSize="12px"
          color="white"
          fontWeight="bold"
          textAlign="center"
        >
          {type}
        </Box> */}
      </Flex>

      <Box mb="20px">
        <Text fontSize="14px" color="#181F27" fontWeight="medium">
          {t('project.subject')}:
        </Text>
        <Box
          border="1px solid #BFCFD6"
          p="12px"
          borderRadius="10px"
          minH="157px"
        >
          <Text lineH="24px" fontSize="14px" color="#464C52">
            {description}
          </Text>
        </Box>
      </Box>

      <Box mb="20px">
        <Text fontSize="14px" color="#181F27" fontWeight="medium">
          {t('ticket.attachments')}:
        </Text>

        <Box border="1px solid #BFCFD6" p="12px" borderRadius="10px">
          {attachments.length == 0 && (
            <Text lineH="24px" fontSize="14px" color="#464C52">
              {t('ticket.noAttachments')}
            </Text>
          )}

          <Box>
            {attachments?.map((e, i) => {
              return (
                <Link
                  href={e}
                  target="_blanck"
                  textDecor="none"
                  _active={{}}
                  _focus={{}}
                  _hover={{}}
                >
                  <FileNameSize
                    image={
                      e.split('.').pop() == 'jpg' ||
                      e.split('.').pop() == 'jpeg'
                        ? jpg
                        : e.split('.').pop() == 'png'
                        ? png
                        : e.split('.').pop() == 'pdf'
                        ? pdf
                        : e.split('.').pop() == 'xlsx'
                        ? xlsx
                        : e.split('.').pop() == 'docx'
                        ? docx
                        : ''
                    }
                    name={e}
                    isClose={false}
                    mb={attachments.length - 1 == i ? '0' : '10px'}
                  />
                </Link>
              );
            })}
          </Box>
        </Box>
      </Box>

      <Button
        bg="#ED6262"
        color="white"
        w="100%"
        onClick={() => mutate(id)}
        _hover={{}}
        _active={{ bg: '#eb4646', boxShadow: 'none' }}
        _focus={{}}
      >
        {t('ticket.deleteTicket')}
      </Button>
    </Box>
  );
};

export default TicketType;
