import { useEffect, useState, useContext } from 'react';
import { userContext } from 'contexts/userContext';
import {
  UnorderedList,
  ListItem,
  Image,
  Box,
  Flex,
  Grid,
  useDisclosure,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Button,
} from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { MdLanguage } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { CustomAvatar, Select } from 'components/shared';
import i18next from 'i18n';
import { useTranslation } from 'react-i18next';
import Notification from 'components/authenticated/notification';
import locationIcon from 'assets/images/icons/location.svg';
import notification from 'assets/images/icons/notification.svg';

const User = ({ sideIsOpen, openSide, changeId }) => {
  const { t } = useTranslation();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const defaultBranchSelected = JSON.parse(
    localStorage.getItem('defaultBranch')
  );

  useEffect(() => {
    setBranch({
      label: defaultBranchSelected.name,
      value: defaultBranchSelected.id,
    });
  }, [JSON.parse(localStorage.getItem('defaultBranch')).value]);

  const direction = localStorage.getItem('i18nextLng');
  const {
    user,
    setLanguage,
    language,
    handleLogOut,
    branchFilter,
    setBranchFilter,
  } = useContext(userContext);

  const mainBranch = user.user.branches[0];
  //this change will appear in sidebar in branch filter
  const [branch, setBranch] = useState({
    label: defaultBranchSelected.name,
    value: defaultBranchSelected.id,
  });

  useEffect(() => {
    setBranchFilter(branch);
  }, []);

  const handleBranch = value => {
    setBranchFilter(value);
    setBranch(value);
    handleDefaultBranch({ name: value.label, id: value.value });
  };

  useEffect(() => {
    if (!sideIsOpen) {
      onClose();
    }
  }, [sideIsOpen, direction]);

  const handleLang = () => {
    if (language == 'ar') {
      setLanguage('en');
      i18next.changeLanguage('en');
    } else {
      setLanguage('ar');
      i18next.changeLanguage('ar');
    }
  };

  const handleDefaultBranch = ({ name, id }) => {
    localStorage.setItem('defaultBranch', JSON.stringify({ name, id }));
  };

  return (
    <UnorderedList px="30px" m="0">
      {/* user  */}
      <ListItem
        pos="relative"
        listStyleType="none"
        mb="12px"
        borderRadius="12px"
        cursor="pointer"
        whiteSpace="nowrap"
      >
        <Flex
          alignItems="center"
          color="text.100"
          boxShadow={!sideIsOpen ? 'none' : '0 0 7px 7px #f3f3f3'}
          justifyContent={!sideIsOpen ? 'center' : 'flex-start'}
          p="5px"
          borderRadius="10px"
          mb="20px"
          as={NavLink}
          to="/profile"
          _activeLink={{ bg: 'bg.100', color: 'text.200', boxShadow: 'none' }}
          onClick={() => changeId('profile')}
        >
          <Box pos="relative">
            <CustomAvatar
              image={user.user.profileImageUrl}
              letter={user.user.firstName[0]}
              w="34px"
              h="34px"
              mb="0"
              m={sideIsOpen ? '0' : 'auto'}
              me={sideIsOpen ? '15px' : 'auto'}
              letterSize="16px"
            />
          </Box>

          <Box
            as="span"
            fontSize="18px"
            display={!sideIsOpen ? 'none' : 'inline'}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            maxW="192px"
          >
            {user.user.firstName} {user.user.lastName}
          </Box>
        </Flex>
      </ListItem>
      <Box display={!sideIsOpen ? 'none' : 'block'}>
        <Flex alignItems="center" justifyContent="space-between" mb="15px">
          <Select
            w="82%"
            // selectTitle={t('modals.selectBranch')}
            label="All branches"
            select={branch}
            handleSelect={handleBranch}
            selections={user.user.branches?.map((item, index) => ({
              label: item.name,
              value: item.id,
            }))}
            // my="20px"
            h="25px"
            paddingButtom="5px"
            buttonRadius="5px"
            borderColor="#F4F9FF"
            icon={locationIcon}
          />
          {/* <Notification>
            <Image src={notification} />
          </Notification> */}
        </Flex>

        <Grid gridTemplateColumns="repeat(2,1fr)" gap="10px">
          <Flex
            borderRadius="5px"
            bg="#F4F9FF"
            alignItems="center"
            p="5px"
            cursor="pointer"
            onClick={handleLang}
          >
            <Box
              as={MdLanguage}
              m={sideIsOpen ? '0' : 'auto'}
              me={sideIsOpen ? '15px' : 'auto'}
              color="#1BA3EF"
            />
            <Box
              as="span"
              fontSize="14px"
              fontWeight="medium"
              color="#414042"
              display={!sideIsOpen ? 'none' : 'inline'}
            >
              {t('login.lang')}
            </Box>
          </Flex>

          <Popover placement="right">
            <PopoverTrigger>
              <Flex
                alignItems="center"
                cursor="pointer"
                bg="#FDEDED"
                p="5px"
                borderRadius="5px"
              >
                <Box
                  as={FiLogOut}
                  color="text.1900"
                  m={sideIsOpen ? '0' : 'auto'}
                  me={sideIsOpen ? '15px' : 'auto'}
                />
                <Text
                  color="text.1900"
                  display={!sideIsOpen ? 'none' : 'inline'}
                >
                  {t('account.exit')}
                </Text>
              </Flex>
            </PopoverTrigger>
            <PopoverContent
              w="170px"
              _focus={{ boxShadow: 'none' }}
              _focusVisible={{
                borderColor: 'transparent',
                boxShadow: 'none',
              }}
            >
              <PopoverArrow />
              <PopoverBody>
                <Text fontSize="14px"> {t('account.logout')}</Text>
                <Text fontSize="12px" my="15px">
                  {t('account.confirmLogout')}
                </Text>
                <Button onClick={handleLogOut} m="auto" display="block">
                  {t('login.confirm')}
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Grid>
      </Box>
    </UnorderedList>
  );
};

export default User;
