import { useState } from 'react';
import { Box, Flex, Button, Image } from '@chakra-ui/react';
import { AiOutlineUpload } from 'react-icons/ai';
import axios from 'axios';
import ImageUploading from 'react-images-uploading';
import FileNameSize from './file-name-size';
import png from 'assets/images/icons/png.svg';
import jpg from 'assets/images/icons/jpg.svg';
import pdf from 'assets/images/icons/pdf.svg';
import xlsx from 'assets/images/icons/xlsx.svg';
import docx from 'assets/images/icons/docx.svg';

const UploadAttachments = ({ setUploadFiles }) => {
  const [images, setImages] = useState([]);

  const maxNumber = 3;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    const fileDetails = e => {
      const fd = new FormData();

      for (let i = 0; i < imageList.length; i++) {
        fd.append('files', imageList[i].file);
      }

      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/uploads/tickets`, fd)
        .then(res => {
          const fileNames = res?.data?.map(e => {
            return e.filename;
          });
          setUploadFiles(fileNames);
        });
    };

    fileDetails();
    setImages(imageList);
  };

  return (
    <Box h="100%">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        allowNonImageType
        acceptType={['jpg', 'gif', 'png', 'pdf', 'xlsx', 'docx']}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <Flex flexDir="column" h="100%">
            {imageList.map((image, index) => (
              <FileNameSize
                image={
                  image.file.type == 'image/jpg' ||
                  image.file.type == 'image/jpeg'
                    ? jpg
                    : image.file.type == 'image/png'
                    ? png
                    : image.file.type == 'application/pdf'
                    ? pdf
                    : image.file.type ==
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    ? docx
                    : image.file.type ==
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    ? xlsx
                    : ''
                }
                name={image.file.name}
                size={(image.file.size / 1000).toFixed(2) + ' ' + 'kb'}
                h="calc(100% / 3)"
                mb={
                  imageList.length - 1 == index && imageList.length > 2
                    ? '0'
                    : '12px'
                }
                onClose={() => onImageRemove(index)}
              />
              // <Flex key={index} h="calc(100% / 3)" bg="red" overflow="hidden">
              //   <Image src={image['data_url']} alt="" width="100%" h="100%" />
              //   <Box>
              //     {/* <Button onClick={() => onImageUpdate(index)}>Update</Button> */}
              //     <Button onClick={() => onImageRemove(index)}>Remove</Button>
              //   </Box>
              // </Flex>
            ))}

            {imageList.length == 3 ? null : (
              <Flex
                border="2px dashed #788596"
                h={
                  imageList.length == 1
                    ? 'calc(100% - (100% / 3))'
                    : imageList.length == 2
                    ? 'calc(100% / 3)'
                    : '100%'
                }
                justifyContent="center"
                alignItems="center"
                borderRadius="5px"
                bg="bg.2200"
                cursor="pointer"
                opacity={isDragging ? 0.5 : 1}
                onClick={onImageUpload}
                {...dragProps}
              >
                <Box
                  as={AiOutlineUpload}
                  fontSize={imageList.length > 0 ? '30px' : '45px'}
                />
              </Flex>
            )}

            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
          </Flex>
        )}
      </ImageUploading>
    </Box>
  );
};

export default UploadAttachments;
