import * as Yup from 'yup';

const regexEn = /^[A-Za-z0-9 ]*$/;
const regexAr = /^[\u0600-\u06FF0-9 ]*$/;

export const WorkshopSchema = Yup.object().shape({
  workshopNameEN: Yup.string()
    .min(3, 'signup.workshopName3Char')
    .max(100, 'signup.workshopName100Char')
    .required('signup.fieldRequired')
    .test('onlyEnglish', 'signup.onlyEnglish', name =>
      regexEn.test(name) ? true : false
    ),
  workshopNameAR: Yup.string()
    .min(3, 'اسم الورشة يجب أن يكون 3 حروف أو أكثر')
    .max(100, 'اسم الورشة يجب أن يكون 100 حرف أو أقل')
    .required('هذا الحقل مطلوب')
    .test('onlyArabic', 'اسم الورشة يجب أن يكون بالعربية', name =>
      regexAr.test(name) ? true : false
    ),
  street: Yup.string()
    .min(3, 'signup.street3Char')
    .max(250, 'signup.street250Char')
    .required('signup.fieldRequired'),
  city: Yup.string()
    .min(3, 'signup.city3Char')
    .max(250, 'signup.city250Char')
    .required('signup.fieldRequired'),
  zipCode: Yup.string()
    .min(3, 'signup.zipCode3Char')
    .max(250, 'signup.zipCode250Char')
    .required('signup.fieldRequired'),
  commercialNumber: Yup.string()
    .required('signup.fieldRequired')
    .test('numberOfDigits', 'signup.commercial10Char', cn =>
      cn && cn.length === 10 ? true : false
    ),

    taxNumber:Yup.string().trim().length(15, 'Tax Number must be 15 digits')
    .matches(/^3\d{13}3$/, 'Tax Number must start and end with the digit 3') 
    

});
